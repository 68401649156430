<template>
    <div class="detail" v-if="info">
        <div class="title">{{ info.title }}</div>
        <div class="time">{{ info.postAt }}</div>
        <div class="tag-container">
            <div class="tag-title">{{ "标签" | language }}</div>
            <div class="tag-list">
                <div
                    class="tag-item"
                    :class="{ select: row == type }"
                    v-for="row in info.tags"
                    :key="row"
                >
                    {{ row }}
                </div>
            </div>
        </div>
        <video
            v-if="id === '5e46168919bb385935b33a0b'"
            controls
            src="https://video.xuexi.cn/customerTrans/5fe46739b9b2bda7e29d0210237f56e1/1aed5e37-162324c9a0e-0005-0cf3-24e-05740.mp4"
        ></video>
        <div class="content" v-html="info.content"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            info: null,
            id: "",
            type: ""
        };
    },
    mounted() {
        this.id = this.$route.params.id;
        this.type = this.$route.query.type;
        this.fetchData();
    },
    methods: {
        async fetchData() {
            let res = await this.$http.get(`/achievement/${this.id}`);
            this.info = res;
        }
    }
};
</script>

<style lang="less" scoped>
.detail {
    .title {
        text-align: left;
        margin-bottom: 10px;
        font-size: 30px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.9);
        line-height: 32px;
    }
    .time {
        text-align: left;
        margin-bottom: 24px;
        height: 22px;
        font-size: 16px;

        color: rgba(0, 0, 0, 0.3);
        line-height: 22px;
    }
    .tag-container {
        margin-bottom: 40px;
        display: flex;
        align-items: flex-start;
        .tag-title {
            width: 32px;
            height: 35px;
            font-size: 16px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
            line-height: 35px;
        }
        .tag-list {
            margin-left: 20px;
            flex: 1;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .tag-item {
                margin-right: 20px;
                padding: 0 20px;
                height: 32px;
                border-radius: 2px;
                border: 1px solid rgba(0, 145, 255, 1);
                font-size: 12px;
                font-weight: 400;
                color: rgba(0, 145, 255, 1);
                line-height: 32px;
                margin-bottom: 20px;
            }
            .select {
                background: rgba(0, 145, 255, 1);
                color: #fff;
            }
        }
    }
    .content {
        margin-bottom: 40px;
        text-align: left;
        text-indent: 2em;
    }
}
@media screen and (max-width: 960px) {
    .detail {
        .time {
            margin-bottom: 10px;
        }
        .tag-container {
            margin-bottom: 30px;
            .tag-list {
                .tag-item {
                    margin-bottom: 10px;
                    margin-right: 10px;
                }
            }
        }
    }
}
video {
    max-width: 100%;
}
</style>
<style>
.content img {
    max-width: 100%;
    object-fit: cover;
}
</style>
